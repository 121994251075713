import React, { memo } from 'react';

import IconMagic from 'shared/ui/icons/magic/IconMagic';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { selectedVideoProvider } from 'app/store/app.selectors';

import { generateVideoScriptAction } from '../model/actions';

type Props = {
  disable?: boolean;
  structureItemId?: string;
  onChangeTab: (key: string) => void;
};

const GenerateVideoScriptButton: React.FC<Props> = ({ disable, structureItemId, onChangeTab }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const videoProvider = useAppSelector(selectedVideoProvider);

  const onClick = () => {
    if (structureItemId) {
      onChangeTab(videoProvider === 'constructor' ? 'studio' : 'script');
      void dispatch(generateVideoScriptAction(structureItemId));
    }
  };

  return (
    <Button size="large" exSize="xl" type="primary" disabled={disable} onClick={onClick}>
      {t('assignments.video.genScript')}
      <IconMagic size={16} />
    </Button>
  );
};

export default memo(GenerateVideoScriptButton);
