export const ENDPOINTS = {
  //AUTH
  LOGIN: 'token/',
  REFRESH_TOKEN: 'token/refresh/',
  SIGNUP: 'register/',

  //ASYNC STATE
  ASYNC_REQUEST_STATE: (id?: number) => `/async-request-state/${id}/`,
  COMPANY_ASYNC_TASK_REQUEST_STATE: (id: number) => `/company/async_task/state/${id}/`,
  EXPORT_STATE: (id: number) => `/export/${id}/`,

  //OUTLINE BUILDER PROJECT
  OUTLINE_BUILDER_PROJECT: '/outline_builder_project/',
  OUTLINE_BUILDER_PROJECT_BY_ID: (id: string) => `/outline_builder_project/${id}/`,
  OUTLINE_BUILDER_PROJECT_PUBLIC: (id: string) => `/outline_builder_project/${id}/public/`,
  OUTLINE_BUILDER_PROJECT_VERSION: (id: string) => `/outline_builder_project/${id}/version/`,
  OUTLINE_BUILDER_PROJECT_FILE: (id: string) => `/outline_builder_project/${id}/file/`,
  OUTLINE_BUILDER_PROJECT_VERSION_EXPORT: `/outline_builder_project_version/export/`,
  OUTLINE_BUILDER_HELP_REQUEST: '/outline_builder_help_request/',

  //OUTLINE BUILDER CONTEXT FILE
  OUTLINE_BUILDER_CONTEXT_FILE: (id: string) => `/outline_builder_context_file/${id}/`,
  OUTLINE_BUILDER_ASSIGNMENT_CONTEXT_FILE: (id: string) => `/outline_builder_assignment_context_file/${id}/`,

  //OUTLINE BUILDER PROJECT VERSION
  OUTLINE_BUILDER_PROJECT_VERSION_BY_ID: (id: string) => `/outline_builder_project_version/${id}/`,
  OUTLINE_BUILDER_PROJECT_VERSION_BY_ID_MODULE_OUTCOME: (id: string) =>
    `/outline_builder_project_version/${id}/project_module_outcome/`,
  OUTLINE_BUILDER_PROJECT_VERSION_BY_ID_MODULE_OUTCOMES: (id: string) =>
    `/outline_builder_project_version/${id}/project_module_outcomes/`,
  OUTLINE_BUILDER_PROJECT_VERSION_MODULE_OUTCOME_NEXT: (id: string) =>
    `/outline_builder_project_version/${id}/project_module_outcome/next/`,
  OUTLINE_BUILDER_PROJECT_VERSION_FEEDBACK: (id: string) => `/outline_builder_project_version/${id}/feedback/`,
  OUTLINE_BUILDER_PROJECT_VERSION_CSV: (id: string) => `/outline_builder_project_version/${id}/csv/`,
  OUTLINE_BUILDER_PROJECT_VERSION_COMMON_CARTRIDGE: (id: string) =>
    `/outline_builder_project_version/${id}/common_cartridge/`,
  OUTLINE_BUILDER_PROJECT_VERSION_GENERATE_QUIZZES: (id: string) =>
    `/outline_builder_project_version/${id}/generate-quizzes/`,
  OUTLINE_BUILDER_PROJECT_VERSION_CCM_IMPORT: (id: string) => `outline_builder_project_version/${id}/ccm-import/`,

  OUTLINE_BUILDER_PROJECT_VERSION_SCORM: (verId: string) => `outline_builder_project_version/${verId}/scorm/`,

  OUTLINE_BUILDER_PROJECT_VERSION_PROJECT_MODULE_OUTCOMES_REGEN: (id: string) =>
    `/outline_builder_project_version/${id}/project_module_outcomes/regenerate/`,

  OUTLINE_BUILDER_PROJECT_VERSION_STRUCTURE_ITEMS_REGEN: (id: string) =>
    `/outline_builder_project_version/${id}/structure_items/regenerate/`,

  //OUTLINE BUILDER PROJECT VERSION REGENERATE
  OUTLINE_BUILDER_PROJECT_VERSION_REGENERATE: (id: string, entity: 'title' | 'description') =>
    `/outline_builder_project_version/${id}/regenerate/${entity}/`,
  //OUTLINE BUILDER PROJECT VERSION TRANSLATE
  OUTLINE_BUILDER_PROJECT_VERSION_TRANSLATE: (id: string) => `/outline_builder_project_version/${id}/translate/`,

  //OUTLINE BUILDER PROJECT MODULE OUTCOME
  OUTLINE_BUILDER_PROJECT_MODULE_OUTCOME: (id: string) => `/outline_builder_project_module_outcome/${id}/`,
  OUTLINE_BUILDER_PROJECT_MODULE_OUTCOME_SUB: (id: string) =>
    `/outline_builder_project_module_outcome/${id}/project_sub_module_outcome/`,
  OUTLINE_BUILDER_PROJECT_VERSION_BY_ID_SUB_MODULE_OUTCOMES: (id: string) =>
    `/outline_builder_project_version/${id}/project_sub_module_outcomes/`,

  //OUTLINE BUILDER PROJECT MODULE OUTCOME REGENERATE
  OUTLINE_BUILDER_PROJECT_MODULE_OUTCOME_REGENERATE: (id: string) =>
    `/outline_builder_project_module_outcome/${id}/regenerate/`,
  OUTLINE_BUILDER_PROJECT_SUB_MODULE_OUTCOME_REGENERATE: (id: string) =>
    `/outline_builder_project_sub_module_outcome/${id}/regenerate/`,

  //OUTLINE BUILDER PROJECT SUB MODULE OUTCOME
  OUTLINE_BUILDER_PROJECT_SUB_MODULE_OUTCOME: (id: string) => `/outline_builder_project_sub_module_outcome/${id}/`,

  //OUTLINE BUILDER PROJECT STRUCTURE
  OUTLINE_BUILDER_PROJECT_STRUCTURE_ITEM: (id: string) => `/outline_builder_project_version/${id}/structure_item/`,
  OUTLINE_BUILDER_PROJECT_STRUCTURE_ITEMS: (id: string) => `/outline_builder_project_version/${id}/structure_items/`,

  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM: (id: string) => `/outline_builder_course_structure_item/${id}/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_TYPE: (id: string) => `/outline_builder_course_structure_item/${id}/type/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_OUTCOMES: (id: string) =>
    `/outline_builder_course_structure_item/${id}/outcomes/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_POSITION: (id: string) =>
    `/outline_builder_course_structure_item/${id}/position/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_REGENERATE: (id: string) =>
    `/outline_builder_course_structure_item/${id}/regenerate/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_QUIZ: (id: string) =>
    `/outline_builder_course_structure_item/${id}/generate-quiz/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_QUIZ: (id: string) => `/outline_builder_course_structure_item/${id}/quiz/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_VIDEO_SETTINGS: (id: string) =>
    `/outline_builder_course_structure_item/${id}/video-settings/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_VIDEO_POINTS: (id: string) =>
    `/outline_builder_course_structure_item/${id}/generate-video-points/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_VIDEO_SCRIPT: (id: string) =>
    `/outline_builder_course_structure_item/${id}/generate-video-script/`,

  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_REGENERATE_VIDEO_SCRIPT: (id: string) =>
    `/outline_builder_course_structure_item/${id}/regenerate-video-script/`,

  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_CREATE_VIDEO: (id: string) =>
    `/outline_builder_course_structure_item/${id}/create-video/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_VIDEO_POINT: (id: string) =>
    `outline_builder_course_structure_item/${id}/video-point/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_FILE: (id: string) => `outline_builder_course_structure_item/${id}/file/`,
  OUTLINE_BUILDER_COURSE_STRUCTURE_ITEM_GENERATE_READING: (id: string) =>
    `/outline_builder_course_structure_item/${id}/generate-reading/`,
  COURSE_STRUCTURE_ITEM_VIDEO_EXTERNAL_URL: (id: string) =>
    `/outline_builder_course_structure_item/${id}/video-external-url/`,

  STRUCTURE_ITEM_VIDEO_AVATAR_POSITION: (strItemId: string) =>
    `outline_builder_assignment/video-avatar-position/${strItemId}/`,
  STRUCTURE_ITEM_VIDEO_AVATAR_SETTINGS: (strItemId: string) =>
    `outline_builder_assignment/video-avatar-settings/${strItemId}/`,

  //VIDEO POINT
  ASSIGNMENT_VIDEO_POINT: (id: string) => `outline_builder_assignment/video-point/${id}/`,
  ASSIGNMENT_VIDEO_POINT_REGENERATE: (id: string) => `outline_builder_assignment/video-point/${id}/regenerate/`,

  //FILE
  FILE_STATE: (fileId: number) => `file/${fileId}/state/`,
  CREATE_FILE: (fileType: string) => `file/${fileType}/`,
  UPLOAD_FILE: (fileId: number) => `file/${fileId}/part/`,
  DELETE_FILE: (fileType: string, fileId: string) => `file/${fileType}/${fileId}/`,

  UPLOAD_PRESENTATION_SLIDE: (fileId: string) => `file/${fileId}/presentation/slide/`,
  DELETE_PRESENTATION_SLIDE: (fileId: string, slideId: string) => `file/${fileId}/presentation/slide/${slideId}/`,

  //FILE EDITOR
  UPDATE_DOCUMENT: (fileId: string) => `file/${fileId}/document/`,

  //AUTO_GENERATION
  CURRICULUM: (id?: string) =>
    id ? `/outline_builder_project/curriculum/${id}/` : '/outline_builder_project/curriculum/',
  AUTO_GENERATION_START: (id: string) => `/outline_builder_project/${id}/autogeneration/start/`,
  AUTO_GENERATION_CONTINUE: (id: string) => `/outline_builder_project/${id}/autogeneration/continue/`,

  //PROPOSAL
  PROPOSAL: '/outline_builder_proposal/',

  //ASSIGNMENT
  ASSIGNMENT_QUIZ: (id: string) => `/outline_builder_assignment/quiz/${id}/`,
  ASSIGNMENT_QUIZ_GEN_ANSWERS: (id: string) => `/outline_builder_assignment/quiz/${id}/generate-answers/`,

  //VIDEO PRESENTATION
  ASSIGNMENT_VIDEO_PRESENTATION: (structureItemId: string) =>
    `outline_builder_assignment/video-presentation/${structureItemId}/`,
  ASSIGNMENT_VIDEO_SCRIPT: (structureItemId: string) => `outline_builder_assignment/video-script/${structureItemId}/`,

  //STRIPE
  STRIPE_CHECKOUT: `/stripe/checkout/`,
  STRIPE_PORTAL_SESSION: `/stripe/portal-session/`,
  STRIPE_DOWNGRADE: '/stripe/downgrade/',

  //USER
  USER_PROMO_MESSAGE: `/user/promo-message/`,
  USER_PROFILE: 'user/profile/',
  CHANGE_PASS: 'user/password/',

  //DCCONNECTOR

  DCCONNECTOR_ACCOUNT: `/dcconnector_account/`,
  DCCONNECTOR_ACCOUNT_UNLINK: `/dcconnector_account/unlink/`,
  EXTERNAL_DCCONNECTOR_ACCOUNT: `/external/dcconnector_account/`,

  //DOCUMENT
  DOCUMENT_SECTION_ORDER: (fileId: string, sectionId: string) => `/document/${fileId}/section/${sectionId}/order/`,
  DOCUMENT_SECTION_UPDATE: (fileId: string, sectionId: string) => `/document/${fileId}/section/${sectionId}/`,
  DOCUMENT_SECTION: (fileId: string) => `/document/${fileId}/section/`,
};
