import React, { useCallback } from 'react';

import Button from 'shared/ui/button';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { setAddQuiz } from 'entities/assignment/model';

import QuizCard from '../quiz-card';

const AddQuizCard: React.FC = () => {
  const { t } = useTranslation();
  const isShowCard = useAppSelector((state) => state.assignment.isAddQuiz);
  const questionsQuiz = useAppSelector((state) => state.assignment.questionsQuiz);
  const dispatch = useAppDispatch();
  const isGeneration = useAppSelector((s) => s.project.generation);
  const selectedVersion = useAppSelector((s) => s.project.selectedVersion);

  const onShowCard = useCallback(() => {
    dispatch(setAddQuiz(true));
  }, [dispatch]);

  const emptyCard = {
    id: '',
    content: '',
    content_type: '',
    order: (questionsQuiz?.content_items?.length || 1) + 1,
  };
  return (
    <div>
      {isShowCard ? (
        <QuizCard isNew={true} item={emptyCard} />
      ) : (
        <Button size="large" exSize="xl" disabled={isGeneration || !!selectedVersion} onClick={onShowCard}>
          <PlusOutlined />
          {t('quizCard.addQuestion')}
        </Button>
      )}
    </div>
  );
};

export default AddQuizCard;
