import { message } from 'antd';
import { AppDispatch, store } from 'shared/store/store';
import { checkAsyncState, messageError, messageGeneration, messageRejectedGeneration } from 'shared/utils';
import { setProjectGeneration } from 'modules/project';
import {
  GenReadingPayload,
  postStructureItemGenerateReading,
} from 'shared/api/requests/outline-builder-project-structure';
import { getAssignmentItemAction } from 'entities/assignment/model';

export const generateReadingAction = (structureItemId: string, payload: GenReadingPayload) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;
    messageGeneration();
    dispatch(setProjectGeneration(true));

    const intermediateRequest = () => {
      if (assign?.id) {
        dispatch(getAssignmentItemAction(assign.id, true));
      }
    };

    const finishRequest = () => {
      if (assign?.id) {
        dispatch(getAssignmentItemAction(assign.id, true));
      }
      dispatch(setProjectGeneration(false));
      message.destroy();
    };

    const rejectedRequest = () => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageRejectedGeneration();
    };

    await postStructureItemGenerateReading(structureItemId, payload)
      .then((response) => {
        checkAsyncState(response.request_id, finishRequest, rejectedRequest, intermediateRequest);
      })
      .catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
  };
};
