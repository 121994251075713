import React, { useCallback, useState } from 'react';

import AssignmentsHead from 'modules/assignments-head';
import { sortVideoPoints } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import { GenerateVideoScriptButton } from 'features/assignment-video-gen-buttons';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import EditableCard from 'entities/editable-card/ui/EditableCard';
import { KeyPoint } from 'features/key-points';
import { addVideoPointAction, updateVideoPointAction } from 'features/key-points/model/actions';
import Button from 'shared/ui/button';
import { useCurrentAssignment } from 'entities/assignment/model';

import { useOwner } from '../../../../shared/hooks/useProjectOwner';

type Props = {
  onChangeTab: (key: string) => void;
};

const AssignmentVideoPoints: React.FC<Props> = ({ onChangeTab }) => {
  const [addKey, setAddKey] = useState(false);
  const { t } = useTranslation();
  const { loading, assignment } = useCurrentAssignment();
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const dispatch = useAppDispatch();
  const { isOwner } = useOwner();
  const disabled = !isOwner || projectGenerate || loading || !!selectedVersion;

  const onAddClick = () => setAddKey(true);

  const onSubmitEdit = useCallback(
    (text?: string, itemId?: string) => {
      if (itemId && text) {
        const payload = {
          name: text,
        };
        void dispatch(updateVideoPointAction(itemId, payload));
      }
    },
    [dispatch]
  );

  const onSubmitAddNewPoint = useCallback(
    (text?: string) => {
      if (!text) {
        setAddKey(false);
      } else {
        const payload = {
          name: text,
        };
        void dispatch(addVideoPointAction(payload)).then(() => setAddKey(false));
      }
    },
    [dispatch]
  );

  return (
    <div className="assign-video-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
      </div>
      <div className="assignment-select-video-points-list">
        <BlockTitle className="mb-4" text={t('assignments.video.pointsTitle')} />
        {sortVideoPoints(assignment?.assignment?.video?.points)?.map((item) => {
          return (
            <EditableCard
              key={item.id}
              disabled={disabled || !!selectedVersion}
              color={item.color}
              itemId={item.id}
              val={item.name}
              onSubmit={onSubmitEdit}
              actions={[
                <KeyPoint.RegenButton key="regen" itemId={item.id} />,
                <KeyPoint.RemoveButton key="remove" itemId={item.id} />,
              ]}
            />
          );
        })}
        {addKey ? <EditableCard autoFocus disabled={disabled} onSubmit={onSubmitAddNewPoint} /> : null}
      </div>
      <div className="d-flex justify-space-between">
        <Button size="large" exSize="xl" disabled={disabled} onClick={onAddClick}>
          {t('assignments.video.addKeyPoint')}
        </Button>
        <GenerateVideoScriptButton disable={disabled} structureItemId={assignment?.id} onChangeTab={onChangeTab} />
      </div>
    </div>
  );
};

export default AssignmentVideoPoints;
