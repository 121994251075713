import { message } from 'antd';
import {
  deleteModuleOutcomeRequest,
  deleteSubModuleOutcomeRequest,
  ModuleOutcomePayload,
  postModuleOutcomeRegenerateRequest,
  postSubModuleOutcomeRegenerateRequest,
  putModuleOutcomeRequest,
  putSubModuleOutcomeRequest,
} from 'shared/api/requests/outline-builder-project-module-outcome';
import { messageError, messageSave } from 'shared/utils';
import { localStorageService } from 'shared/services/localStorageService';
import { AppDispatch, store } from 'shared/store/store';

import { getProjectAction } from '../../project/actions';
import { setProjectGeneration } from '../../project';

const finishRequestAction = () => {
  return (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    const lang = project?.active_project_version?.language.code || localStorageService.getLangData();
    if (project?.id && lang) {
      void dispatch(getProjectAction(project?.id, true, undefined, lang));
    }
    message.destroy();
  };
};

export const updateModuleOutcomeAction = (moduleId: string, payload: ModuleOutcomePayload) => {
  return async (dispatch: AppDispatch) => {
    messageSave();

    await putModuleOutcomeRequest(moduleId, payload)
      .then(() => {
        dispatch(finishRequestAction());
      })
      .catch((err) => {
        console.log(err);
        dispatch(finishRequestAction());
      });
  };
};

export const deleteModuleOutcomeAction = (moduleId: string) => {
  return async (dispatch: AppDispatch) => {
    messageSave();

    await deleteModuleOutcomeRequest(moduleId)
      .then(() => {
        dispatch(finishRequestAction());
      })
      .catch((err) => {
        console.log(err);
        dispatch(finishRequestAction());
      });
  };
};

export const updateSubModuleOutcomeAction = (moduleId: string, payload: ModuleOutcomePayload) => {
  return async (dispatch: AppDispatch) => {
    messageSave();

    await putSubModuleOutcomeRequest(moduleId, payload)
      .then(() => {
        dispatch(finishRequestAction());
      })
      .catch((err) => {
        console.log(err);
        dispatch(finishRequestAction());
      });
  };
};

export const deleteSubModuleOutcomeAction = (moduleId: string) => {
  return async (dispatch: AppDispatch) => {
    messageSave();

    await deleteSubModuleOutcomeRequest(moduleId)
      .then(() => {
        dispatch(finishRequestAction());
      })
      .catch((err) => {
        console.log(err);
        dispatch(finishRequestAction());
      });
  };
};

export const regenerateModuleOutcomeAction = (moduleId: string, isParent: boolean, text: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    if (isParent) {
      const payload = {
        for_regeneration_primary_outcome: text,
      };
      await postModuleOutcomeRegenerateRequest(moduleId, payload).catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
    } else {
      const payload = {
        for_regeneration_secondary_outcome: text,
      };
      await postSubModuleOutcomeRegenerateRequest(moduleId, payload).catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
    }
  };
};
