import api, { ApiModules } from 'shared/api/index';

import { ENDPOINTS } from '../../constants';

export type UserPromoMessagePayload = {
  ids: number[];
  is_active: boolean;
};

export const putUserPromoMessage = (payload: UserPromoMessagePayload) =>
  api(ApiModules.ACCOUNT).put(ENDPOINTS.USER_PROMO_MESSAGE, payload);

export const putUpdateUserProfile = (formData: FormData) =>
  api(ApiModules.ACCOUNT).put(ENDPOINTS.USER_PROFILE, formData);

export type ChangePassPayload = {
  password: string;
  password2: string;
  old_password: string;
};

export const putChangePassRequest = (payload: ChangePassPayload) =>
  api(ApiModules.ACCOUNT).put(ENDPOINTS.CHANGE_PASS, payload);

export type HelpRequestPayload = {
  email: string;
  page_link: string;
  help_role_id: number;
  help_work_type_id: number;
};

export const postHelpRequest = (payload: HelpRequestPayload) =>
  api(ApiModules.OUTLINE_BUILDER).post(ENDPOINTS.OUTLINE_BUILDER_HELP_REQUEST, payload);
