import React, { ChangeEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import AssignmentsHead from 'modules/assignments-head';
import BlockTitle from 'shared/ui/block-title/BlockTitle';
import Input from 'shared/ui/input';
import { GenerateReadingButton } from 'features/assignment-reading';
import { useAppSelector } from 'shared/store/hooks';
import { useCurrentAssignment } from 'entities/assignment/model';

import ViewPendingGenerate from '../../shared/ui/viewPendingGeneration/viewPendingGenerate';
import { ScriptEditor } from '../../features/assignment-editor';
import WordCounterView from '../../shared/ui/wordCounterView/WordCounterView';
import useWordCount from '../../shared/hooks/useWordCount';
import { useOwner } from '../../shared/hooks/useProjectOwner';

const AssignmentReadingWidget = () => {
  const [symbols, setSymbols] = useState<number | string>(1000);
  const { t } = useTranslation();
  const { loading, assignment } = useCurrentAssignment();
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const file = useAppSelector((state) => state?.assignment?.assignment?.assignment?.reading?.file);
  const { isOwner } = useOwner();
  const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/, '');
    //const validated = Number(value) > 10 ? 10 : value;
    setSymbols(value);
  };
  const disabled = !isOwner || projectGenerate || loading || !!selectedVersion;

  const originalDataStr = file?.related_files[0]?.original_data;
  const data = originalDataStr ? JSON.parse(originalDataStr) : undefined;

  const { words, charsWithSpace, charsWithoutSpace } = useWordCount(data, !projectGenerate || !!file);

  return (
    <div className="assign-reading-content__wrap">
      <div className="assignment-select__block">
        <AssignmentsHead />
        {/*<div className="assignment-select__block w-auto">*/}
        {/*  <BlockTitle className="mb-0" text={t('assignments.reading.contentLength')} />*/}
        {/*  <div>*/}
        {/*    <Input*/}
        {/*      disabled={disabled}*/}
        {/*      style={{ width: 100 }}*/}
        {/*      size="large"*/}
        {/*      value={symbols}*/}
        {/*      //onBlur={onBlur}*/}
        {/*      onChange={onHandleChange}*/}
        {/*    />*/}
        {/*    <BlockTitle className="ml-2" text={t('assignments.reading.characters')} />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="d-flex justify-end">
          <GenerateReadingButton contentLength={Number(symbols)} structureItemId={assignment?.id} disable={disabled} />
        </div>
      </div>

      {!!file || projectGenerate ? (
        <div className="assignment-select__block">
          {file && !projectGenerate ? (
            <BlockTitle className="mb-0" text={t('assignments.reading.titleEditor')} />
          ) : null}
          {projectGenerate ? (
            <ViewPendingGenerate
              title={t<string>('assignments.reading.readingPending.text1')}
              text={t<string>('assignments.reading.readingPending.text2')}
            />
          ) : (
            <ScriptEditor file={file} />
          )}
        </div>
      ) : null}

      {!projectGenerate && !!file ? (
        <WordCounterView
          className="assign-video-words"
          words={words}
          charsWithSpace={charsWithSpace}
          charsWithoutSpace={charsWithoutSpace}
        />
      ) : null}
    </div>
  );
};

export default AssignmentReadingWidget;
