import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { cx } from 'shared/utils';
import Logo from 'shared/components/logo';

import { Subscription } from '../../model';

import './styles.scss';

type Props = {
  data: Subscription;
  action?: ReactNode;
};

const appCompany = process.env.REACT_APP_CCM_COMPANY;

const PriceCard = ({ data, action }: Props) => {
  const { t } = useTranslation();
  const description = data.description.split(/\r\n|\r|\n/);
  const isCorp = data.type === 'corp';

  const classNames = cx('price-card__logo', {
    'price-card__logo-w': appCompany === 'constr',
  });

  return (
    <div className="price-card">
      <div className="price-card__head">
        <span className="price-card__title">{data.name}</span>

        {isCorp ? (
          <div className={classNames}>
            <Logo />
          </div>
        ) : (
          <span className="price-card__value">
            <sup>$</sup>
            {data.price}
          </span>
        )}
      </div>
      {!isCorp ? (
        <span className="price-card__hint">{t('common.perMonth')}</span>
      ) : (
        <div className="price-card__hint price-card__hint--empty" />
      )}

      <div className="price-card__content">
        {description.map((str, index) => (
          <p key={index}>{str}</p>
        ))}
      </div>
      <div className="price-card__footer">{action}</div>
    </div>
  );
};

export default PriceCard;
