import type { FC } from 'react';

import { cx } from 'shared/utils';
import { useAppSelector } from 'shared/store/hooks';
import { VideoAvatarPosition } from 'entities/studio/model/types';
import { useSearchParams } from 'react-router-dom';
import { selectProjectDisabled } from 'modules/project/project.selectors';
import { useOwner } from 'shared/hooks/useProjectOwner';
import { useTranslation } from 'react-i18next';
import useStructureItemVideoSettings from 'shared/graphql/queries/useStructureItemVideoSettings';

import { putVideoAvatarPositionRequest } from '../../api';

import { buttonsMap } from './constants';

import './styles.scss';

type Props = {
  item: {
    key: VideoAvatarPosition;
    icon: string;
  };
  disabled: boolean;
};

const AvatarPositionItem: FC<Props> = ({ item, disabled }) => {
  const [searchParams] = useSearchParams();
  const strItemId = searchParams.get('str');

  const { data: settings, refetch } = useStructureItemVideoSettings(strItemId);

  const onChangePosition = async () => {
    if (disabled) {
      return;
    }
    if (strItemId) {
      const payload = {
        avatar_position_type: item.key,
      };
      await putVideoAvatarPositionRequest(strItemId, payload);
      await refetch();
    }
  };

  const isSelected = item.key === settings?.avatar_position_type;
  const classNames = cx('avatar-position__button', {
    'avatar-position__button--active': isSelected,
    'avatar-position__button--disabled': disabled,
  });
  return (
    <div role="button" className={classNames} onClick={onChangePosition}>
      <img src={item.icon} alt="avatarOnly" />
    </div>
  );
};

export const AvatarPosition: FC = () => {
  const { t } = useTranslation();

  const projectDisabled = useAppSelector(selectProjectDisabled);
  const { isOwner } = useOwner();

  const disabled = !isOwner || projectDisabled;

  return (
    <div className="avatar-position">
      <span className="avatar-position__title">{t('assignments.video.studio.positionTitle')}</span>
      <div className="avatar-position__actions">
        {buttonsMap.map((el) => (
          <AvatarPositionItem disabled={disabled} key={el.key} item={el} />
        ))}
      </div>
    </div>
  );
};
