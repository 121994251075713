import React, { memo, useEffect } from 'react';

import { generatePath, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { PATH_NAME } from 'pages/constants';
import { isEmpty } from 'lodash';
import LinkButton from 'modules/link-button';
import { setProject, setProjectGeneration, setSelectedVersion } from 'modules/project';
import { useTranslation } from 'react-i18next';
import ContentLanguageButton from 'modules/content-language-button';
import HeaderNav from 'shared/components/header-nav';
import HeaderSmall from 'shared/components/header-small';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import PromoAlert from 'modules/promo-alert';
import { App, Dropdown, message } from 'antd';
import { EllipsisOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import GenStatusView from 'shared/ui/gen-status-view';
import Button from 'shared/ui/button/Button';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';
import { ProjectVersion } from 'features/project-version';
import { deleteOutlineBuilderProject } from 'shared/api/requests/outline-builder-project';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { getProjectAction, getPublicProjectAction } from '../../modules/project/actions';
import { setStructure } from '../course-structure/store';
import useProjectWebSockets from '../../modules/project/useProjectWebSockets';
import { getStructureAction } from '../course-structure/store/actions';
import { DropdownLabel } from '../../modules/course-structure-collapse/more-actions/MoreActions';

import { courseNavigation } from './constants';

import './styles.scss';

const CourseHeaderManual: React.FC = () => {
  const { id, lang } = useParams();
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { navigate, canGoBack, goBack } = useCustomNavigator();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const project = useAppSelector((state) => state.project.project);
  const generation = useAppSelector((state) => state.project.generation);
  const structureStore = useAppSelector((s) => s.structure);
  const disableAbout = !project?.active_project_version;
  const disableLO = isEmpty(project?.active_project_version?.active_modules_outcomes);
  const disableStructure = !project?.has_submodules;

  const title = project?.active_project_version?.generated_title || t<string>('common.newCourse');
  const version = searchParams.get('version');
  const location = useLocation();
  const pageEntity = location.pathname.split('/').pop();

  const { pathname } = location;
  const isPublic = pathname.includes('public');

  useProjectWebSockets();

  //Load project
  useEffect(() => {
    if (id && id !== 'new' && lang && !generation) {
      if (isPublic) {
        void dispatch(getPublicProjectAction(id, lang));
        if (project?.id) {
          void dispatch(getStructureAction(project?.id, lang));
        }
      } else {
        void dispatch(getProjectAction(id, false, undefined, lang));
        if (project?.has_submodules) {
          if (version) {
            void dispatch(getStructureAction(id, lang, false, version));
          } else {
            void dispatch(getStructureAction(id, lang, false));
          }
        }
      }
    }
  }, [dispatch, generation, id, isPublic, lang, project?.has_submodules, project?.id, version]);

  useEffect(() => {
    return () => {
      dispatch(setProject(null));
      dispatch(setSelectedVersion(null));
      dispatch(setStructure([]));
      dispatch(setProjectGeneration(false));
      message.destroy();
    };
  }, []);

  const onGoMainPage = () => navigate(PATH_NAME.main);

  const disabled = structureStore.loading || !project?.has_submodules || generation;

  const goExportPage = () => {
    const path = generatePath(PATH_NAME.courseManualWithEntity, {
      id: id,
      lang: lang,
      entity: PATH_NAME.courseEntity.export,
    });
    navigate(path);
  };

  const action = (
    <>
      <ProjectVersion.Select />
      <GenStatusView isGen={generation} />
      <ContentLanguageButton />
      <LinkButton />
      <Button className="tour--export" type="primary" disabled={disabled} onClick={goExportPage}>
        <span className="mr-1">{t('exportButton.text')}</span>
      </Button>
    </>
  );

  const onNavToStructure = () => navigate(PATH_NAME.courseEntity.structure);

  if (pageEntity === PATH_NAME.courseEntity.export) {
    return (
      <div className="course-header">
        <HeaderSmall
          title={title}
          goBack={canGoBack ? goBack : onNavToStructure}
          // actions={
          //   <>
          //     <Export.SelectButton />
          //   </>
          // }
        />
      </div>
    );
  }

  //TODO Need to refactoring P.S. don't have enough time
  const onDeleteProject = async () => {
    if (id) {
      await deleteOutlineBuilderProject(id).then(() => navigate(PATH_NAME.main));
    }
  };

  const onClickDelete = () => {
    modal.confirm({
      title: t('projectExtra.delete.title'),
      icon: <ExclamationCircleFilled />,
      content: t('projectExtra.delete.content'),
      okText: t('projectExtra.delete.submit'),
      cancelText: t('projectExtra.delete.cancel'),
      onOk() {
        void onDeleteProject();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const items = [
    {
      key: 'remove',
      label: <DropdownLabel text="Delete" type="del" action={onClickDelete} />,
    },
  ];

  return (
    <div className="course-header">
      <PromoAlert />
      <HeaderSmall title={title} goBack={onGoMainPage} actions={action} />
      <div className="course-header__wrap">
        <HeaderNav navList={courseNavigation(id, lang, disableAbout, disableLO, disableStructure)} />
        {id && id !== 'new' ? (
          <Dropdown
            overlayClassName="more-action-drop"
            placement="bottomRight"
            trigger={['click']}
            menu={{ items, triggerSubMenuAction: 'click', expandIcon: () => null }}
          >
            <Button
              //disabled={true || !isOwner}
              disabled={true}
              size="small"
              shape="circle"
              type="text"
              icon={<EllipsisOutlined style={{ fontSize: 16 }} />}
            />
          </Dropdown>
        ) : null}
      </div>
    </div>
  );
};

export default memo(CourseHeaderManual);
