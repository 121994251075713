import React, { useEffect } from 'react';

import Editor from 'shared/ui/editor';
import { EditorCore } from '@react-editor-js/core/src/editor-core';
import type { ReadingFile, VideoScriptFile } from 'shared/types/entities';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { useOwner } from 'shared/hooks/useProjectOwner';

import { updateFileDocument } from '../model/actions';

type Props = {
  file?: VideoScriptFile | ReadingFile;
};

export const ScriptEditor: React.FC<Props> = ({ file }) => {
  const editorCore = React.useRef<EditorCore | null>(null);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);
  const dispatch = useAppDispatch();
  const { isOwner } = useOwner();

  const handleInitialize = React.useCallback((instance: EditorCore) => {
    editorCore.current = instance;
  }, []);

  useEffect(() => {
    if (!file) {
      editorCore.current?.destroy();
    }
  }, [file]);

  const handleSave = React.useCallback(async () => {
    const savedData = await editorCore?.current?.save();
    if (file?.id && savedData) {
      void dispatch(updateFileDocument(file?.id, savedData));
    }
  }, [dispatch, file?.id]);

  const originalDataStr = file?.related_files[0]?.original_data;
  const data = originalDataStr ? JSON.parse(originalDataStr) : undefined;

  useEffect(() => {
    if (selectedVersion) {
      editorCore.current?.render(data);
    }
  }, []);

  return data ? (
    <Editor
      disabled={!isOwner || !!selectedVersion}
      data={data}
      onInitialize={handleInitialize}
      onChange={handleSave}
    />
  ) : null;
};
