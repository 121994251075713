import React from 'react';

import { Export } from 'features/export';

const ExportSettingsWidget = () => {
  return (
    <div className="export-settings-widget">
      <Export.Reezonly />
    </div>
  );
};

export default ExportSettingsWidget;
