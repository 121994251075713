import React, { SyntheticEvent } from 'react';

import { useAppSelector } from 'shared/store/hooks';
import Input from 'shared/ui/input';
import Button from 'shared/ui/button/Button';
import { Alert, message } from 'antd';

import './styles.scss';

const InviteButton = () => {
  const affiliateName = useAppSelector((state) => state.app?.me?.affiliate_username);

  const path = `${window.location.origin}/signup?promo=${affiliateName}`;

  const onClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    if (affiliateName) {
      navigator.clipboard.writeText(path).then(() => {
        message.info('Copied');
      });
    }
  };

  return (
    <div className="user-invite">
      <span className="user-invite__title">Invite user</span>
      <div className="user-invite__wrap">
        <div className="user-invite__str-wrap">
          <span className="user-invite__str">{path}</span>
        </div>

        <Button size="large" type="primary" onClick={onClick}>
          Copy
        </Button>
      </div>
    </div>
  );
};

export default InviteButton;
