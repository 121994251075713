import React, { memo } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import Button from 'shared/ui/button/Button';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { deleteVideoPointAction } from '../model/actions';

type Props = {
  itemId: string;
};

const RemoveKeyPointButton = ({ itemId }: Props) => {
  const loading = useAppSelector((state) => state.assignments.loading);
  const projectGenerate = useAppSelector((state) => state.project.generation);

  const dispatch = useAppDispatch();
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);

  const onClick = () => void dispatch(deleteVideoPointAction(itemId));

  const disabled = projectGenerate || loading || !!selectedVersion;

  return (
    <Button
      disabled={disabled}
      size="small"
      type="text"
      shape="circle"
      icon={<DeleteOutlined style={{ fontSize: 14, color: '#D73C1D' }} />}
      onClick={onClick}
    />
  );
};

export default memo(RemoveKeyPointButton);
