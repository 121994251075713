import React, { memo, useCallback } from 'react';

import Button from 'shared/ui/button';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/store/hooks';
import { useCustomNavigator } from 'shared/hooks/useCustomNavigator';

import { PATH_NAME } from '../../pages/constants';

const TokenSubscriptionButton: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useCustomNavigator();
  const me = useAppSelector((state) => state.app.me);
  const hasSubscription = useAppSelector((state) => state.app.me?.has_active_subscription);

  const onClick = useCallback(() => {
    navigate(PATH_NAME.billing);
  }, [navigate]);

  if (!me) {
    return null;
  }

  return (
    <Button size="small" type="default" onClick={onClick}>
      {hasSubscription ? t('card.pro') : t('card.subscribe')}
    </Button>
  );
};

export default memo(TokenSubscriptionButton);
