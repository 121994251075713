import React, { useEffect, useLayoutEffect } from 'react';

import { App as AntApp, ConfigProvider } from 'antd';
import Routing from 'pages';
import { theme } from 'shared/theme/theme';
import { useTranslation } from 'react-i18next';
import Tutorial from 'modules/tutorial/Tutorial';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';
import { Helmet } from 'react-helmet';

import Zendesk, { ZendeskAPI } from '../ZendexConfig';
import { getFavicon } from '../shared/utils/getFavicon';

import { initAuth } from './store/actions';

import './styles/main.scss';

const ZENDESK_KEY = '80e2bd40-4ea5-4d31-bf66-7d56ade27853';
const appCompany = process.env.REACT_APP_CCM_COMPANY;

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const dir = i18n.dir(i18n.language);
  const layout = useAppSelector((s) => s.app.layout);
  const hideActions = layout === 'print' || layout === 'public' || !layout;
  const projectName = useAppSelector((s) => s?.project?.project?.active_project_version?.generated_title);

  useEffect(() => {
    if (projectName) {
      document.title = projectName;
    } else {
      document.title = 'CoPilot';
    }
  }, [projectName]);

  useEffect(() => {
    document.documentElement.dir = dir;
    document.documentElement.lang = i18n.language;
  }, [dir, i18n, i18n.language]);

  useLayoutEffect(() => {
    void dispatch(initAuth());
  }, [dispatch]);

  const handleLoaded = () => {
    ZendeskAPI('messenger', 'open');
  };

  return (
    <ConfigProvider theme={theme} direction={dir}>
      <AntApp>
        <Helmet>
          <link id="favicon" rel="icon" href={getFavicon(appCompany)} type="image/x-icon" />
        </Helmet>
        <Routing />
        <div>{hideActions ? null : <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />}</div>
        <Tutorial />
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
