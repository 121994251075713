import React, { memo } from 'react';

import ButtonSparking from 'shared/components/button-sparking/ButtonSparking';
import RegeneratePopup from 'modules/regenerate-popup';
import { useAppDispatch, useAppSelector } from 'shared/store/hooks';

import { regenerateVideoPointAction } from '../model/actions';

type Props = {
  itemId: string;
};

const RegenKeyPointButton = ({ itemId }: Props) => {
  const loading = useAppSelector((state) => state.assignments.loading);
  const projectGenerate = useAppSelector((state) => state.project.generation);
  const selectedVersion = useAppSelector((state) => state.project.selectedVersion);

  const dispatch = useAppDispatch();

  const onClick = (content: string) => {
    const payload = {
      for_regeneration_video_point: content,
    };
    void dispatch(regenerateVideoPointAction(itemId, payload));
  };

  const disabled = projectGenerate || loading || !!selectedVersion;

  return (
    <RegeneratePopup disabled={disabled} onSubmit={onClick}>
      <ButtonSparking disabled={disabled} size="small" />
    </RegeneratePopup>
  );
};

export default memo(RegenKeyPointButton);
