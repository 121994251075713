import { AppDispatch, store } from 'shared/store/store';
import { getStructureItem } from 'shared/graphql/queries/getStructureItem';
import { messageError, messageGeneration, messageSave } from 'shared/utils';
import { message } from 'antd';
import {
  deleteOutlineBuilderAssignmentQuizRequest,
  postOutlineBuilderAssignmentGenAnswersRequest,
  putOutlineBuilderAssignmentQuizRequest,
} from 'shared/api/requests/assignment';
import {
  GenerateQuizPayload,
  postOutlineBuilderProjectStructureItemGenQuizRequest,
  postOutlineBuilderProjectStructureItemQuizRequest,
  QuizPayload,
} from 'shared/api/requests/outline-builder-project-structure';
import { QuizContentItem } from 'shared/types/entities';
import { postOutlineBuilderProjectVersionGenerateQuizzesRequest } from 'shared/api/requests/outline-builder-project-version';
import { getAssignContextFiles } from 'shared/graphql/queries/getAssignContextFiles';
import { setProjectGeneration } from 'modules/project';

import { setAddQuiz, setAssignment, setAssignmentLoading, setContextFiles } from './assignment';

export const getAssignmentItemAction = (assignmentId: string, isSilent?: boolean) => {
  return async (dispatch: AppDispatch) => {
    await getStructureItem(assignmentId)
      .then((response) => {
        dispatch(setAssignment(response.data.outline_builder_course_structure_item));
      })
      .catch((err) => messageError(err));
  };
};

export const generateQuizAnswersAction = (quizId: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    await postOutlineBuilderAssignmentGenAnswersRequest(quizId).catch((err) => {
      messageError(err);
    });
  };
};

export const generateQuizAction = (itemId: string, payload: GenerateQuizPayload) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setProjectGeneration(true));

    await postOutlineBuilderProjectStructureItemGenQuizRequest(itemId, payload).catch((err) => {
      messageError(err);
      dispatch(setProjectGeneration(false));
      message.destroy();
    });
  };
};

export const createQuizAction = (itemId: string, payload: QuizPayload) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setAssignmentLoading(true));
    messageSave();
    await postOutlineBuilderProjectStructureItemQuizRequest(itemId, payload)
      .then(() => {
        dispatch(getAssignmentItemAction(itemId, true));
        dispatch(setAssignmentLoading(false));
        dispatch(setAddQuiz(false));
      })
      .catch(() => {
        dispatch(setAssignmentLoading(false));
        dispatch(setAddQuiz(false));
      })
      .finally(() => {
        message.destroy();
      });
  };
};

export const updateAssignmentQuizAction = (id: string, payload: QuizContentItem) => {
  return async (dispatch: AppDispatch) => {
    messageSave();
    dispatch(setAssignmentLoading(true));
    const assignment = store.getState().assignment.assignment;

    await putOutlineBuilderAssignmentQuizRequest(id, payload).finally(() => {
      if (assignment) {
        dispatch(getAssignmentItemAction(assignment.id, true));
      }
      dispatch(setAssignmentLoading(false));
      message.destroy();
    });
  };
};

export const removeAssignmentQuizAction = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(setAssignmentLoading(true));
    const assignment = store.getState().assignment.assignment;

    await deleteOutlineBuilderAssignmentQuizRequest(id).finally(() => {
      if (assignment) {
        dispatch(getAssignmentItemAction(assignment.id, true));
      }
      dispatch(setAssignmentLoading(false));
    });
  };
};

export const generateAllQuizzesAction = () => {
  return async (dispatch: AppDispatch) => {
    const project = store.getState().project.project;
    dispatch(setProjectGeneration(true));

    if (project?.active_project_version) {
      messageGeneration();
      await postOutlineBuilderProjectVersionGenerateQuizzesRequest(project.active_project_version.id).catch((err) => {
        messageError(err);
        dispatch(setProjectGeneration(false));
        message.destroy();
      });
    }
  };
};

export const getStructureItemContextFilesAction = (assignmentId: string) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment;

    await getAssignContextFiles(assignmentId).then((response) => {
      if (assign.assignment?.id === assignmentId) {
        dispatch(setContextFiles(response.data.outline_builder_course_structure_item.context_files));
      }

      const state = response.data.outline_builder_course_structure_item.context_files?.[0]?.context_file.state;

      if (state === 'new' || state === 'parsing') {
        setTimeout(() => dispatch(getStructureItemContextFilesAction(assignmentId)), 2000);
      }
    });
  };
};
