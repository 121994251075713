export const getFavicon = (company?: string) => {
  switch (company) {
    case 'constr':
      return '/constructor_favicon.ico';
    case 'smartcat':
      return '/smartcat_black.ico';
    default:
      return '/en.ico';
  }
};
