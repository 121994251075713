import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);
import('dayjs/locale/en');
import('dayjs/locale/es');
import('dayjs/locale/ar');

export const dateFormat = (date: string, f = 'MMM DD', locale = 'en') => {
  return dayjs(date).locale(locale).format(f);
};
