import { message } from 'antd';
import { AppDispatch, store } from 'shared/store/store';
import { checkAsyncState, messageError, messageGeneration, messageRejectedGeneration, messageSave } from 'shared/utils';
import { setAssignmentsLoading } from 'widgets/assignments-list/store';
import {
  AssignmentVideoPointPayload,
  deleteAssignmentVideoPointRequest,
  postAssignmentVideoPointRegenerateRequest,
  postAssignmentVideoPointRequest,
  putAssignmentVideoPointRequest,
  RegenerateVideoPointPayload,
} from 'shared/api/requests/outline-builder-project-structure';
import { setProjectGeneration } from 'modules/project';
import { getAssignmentItemAction } from 'entities/assignment/model';

export const addVideoPointAction = (payload: AssignmentVideoPointPayload) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;

    messageSave();
    dispatch(setAssignmentsLoading(true));

    if (assign) {
      await postAssignmentVideoPointRequest(assign.id, payload)
        .then(() => {
          if (assign?.id) {
            dispatch(getAssignmentItemAction(assign.id, true));
            dispatch(setAssignmentsLoading(false));
            message.destroy();
          }
        })
        .catch(() => {
          dispatch(setAssignmentsLoading(false));
          message.destroy();
        });
    }
  };
};

export const updateVideoPointAction = (id: string, payload: AssignmentVideoPointPayload) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;

    messageSave();
    dispatch(setAssignmentsLoading(true));

    await putAssignmentVideoPointRequest(id, payload)
      .then(() => {
        if (assign?.id) {
          dispatch(getAssignmentItemAction(assign.id, true));
          dispatch(setAssignmentsLoading(false));
          message.destroy();
        }
      })
      .catch(() => {
        dispatch(setAssignmentsLoading(false));
        message.destroy();
      });
  };
};

export const deleteVideoPointAction = (id: string) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;
    messageSave();
    dispatch(setAssignmentsLoading(true));

    await deleteAssignmentVideoPointRequest(id)
      .then(() => {
        if (assign?.id) {
          dispatch(getAssignmentItemAction(assign.id, true));
          dispatch(setAssignmentsLoading(false));
          message.destroy();
        }
      })
      .catch(() => {
        dispatch(setAssignmentsLoading(false));
        message.destroy();
      });
  };
};

export const regenerateVideoPointAction = (id: string, payload: RegenerateVideoPointPayload) => {
  return async (dispatch: AppDispatch) => {
    const assign = store.getState().assignment.assignment;
    messageGeneration();
    dispatch(setProjectGeneration(true));

    const intermediateRequest = () => {
      if (assign?.id) {
        dispatch(getAssignmentItemAction(assign.id, true));
      }
    };

    const finishRequest = () => {
      if (assign?.id) {
        dispatch(getAssignmentItemAction(assign.id, true));
      }
      dispatch(setProjectGeneration(false));
      message.destroy();
    };
    const rejectedRequest = () => {
      dispatch(setProjectGeneration(false));
      message.destroy();
      messageRejectedGeneration();
    };

    await postAssignmentVideoPointRegenerateRequest(id, payload)
      .then((response) => {
        checkAsyncState(response.request_id, finishRequest, rejectedRequest, intermediateRequest);
      })
      .catch((err) => {
        dispatch(setProjectGeneration(false));
        message.destroy();
        messageError(err);
      });
  };
};
