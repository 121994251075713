import React, { memo } from 'react';

import { CBIconProps } from '../types';

import '../styles.scss';

const IconReezonly: React.FC<CBIconProps> = ({ size }: CBIconProps) => {
  const styles = {
    width: size || 24,
    height: size || 24,
  };
  return (
    <span style={styles} className="cb-icon">
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="#D73C1D">
          <path
            d="M2807 4249 c-199 -23 -395 -83 -587 -180 -423 -214 -746 -611 -870
    -1071 -55 -202 -53 -161 -57 -1190 l-4 -958 441 0 440 0 0 889 c0 965 0 959
  56 1110 36 97 111 210 193 291 162 161 362 240 607 240 l104 0 0 440 0 440
  -127 -1 c-71 -1 -159 -5 -196 -10z"
          />
          <path
            d="M3161 1894 c-151 -40 -294 -172 -352 -323 -73 -193 -26 -413 120
    -558 176 -176 452 -208 657 -75 153 99 244 263 244 442 0 236 -141 429 -369
  507 -79 27 -215 30 -300 7z"
          />
        </g>
      </svg>
    </span>
  );
};

export default memo(IconReezonly);
