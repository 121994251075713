import React from 'react';

import IconMagic from 'shared/ui/icons/magic/IconMagic';
import Button from 'shared/ui/button/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'shared/store/hooks';

import { generateReadingAction } from '../model/actions';

type Props = {
  disable?: boolean;
  structureItemId?: string;
  contentLength: number;
};

const GenerateReadingButton = ({ disable, structureItemId, contentLength }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClick = () => {
    const payload = {
      user_target_content_length: contentLength,
    };
    if (structureItemId) {
      void dispatch(generateReadingAction(structureItemId, payload));
    }
  };

  return (
    <Button size="large" exSize="xl" type="primary" disabled={disable} onClick={onClick}>
      {t('assignments.reading.genReading')}
      <IconMagic size={16} />
    </Button>
  );
};

export default GenerateReadingButton;
